
import './App.css';
import { useState, useEffect } from "react";
function App() {


  useEffect(() => {
    redirectUserToNewURL();
  },[]);

  function redirectUserToNewURL(){
    const path = window.location.pathname;
    if(path.includes("quote")){
      window.location.href = "https://www.finestvan.co.uk"+path;
    }
  }

  return (
    <div>
        <img src="/FV-Home-Redirect.png" className="App-logo" alt="logo" />
    </div>
  );
}

export default App;
